module.exports = {
  comm: {
    home: 'ہوم',
    pricing: 'قیمتیں',
    blog: 'آواز کی نقل اور API', // 更新
    language: 'زبان:',
    select_language: 'زبان منتخب کریں',
    txt2voice: 'متن سے آواز',
    voice2txt: 'آواز سے متن',
    voiceclone: 'آواز کلوننگ',
    video2txt: 'ویڈیو سے متن',
    footer_help: 'مدد درکار ہے؟ ہمیں ای میل کریں:',
    footer_mail: 'tiktokaivoicetool@gmail.com',
    login: 'لاگ ان کریں',
    login_desc: 'اپنے TikTok Voice Generator اکاؤنٹ تک رسائی حاصل کرنے کے لیے لاگ ان کریں',
    logout: 'لاگ آؤٹ کریں',
  },
  txt2voice: {
    title: 'TikTok آواز جنریٹر: مزاحیہ AI TikTok آوازیں پیدا کریں',
    description: 'مزاحیہ AI TikTok آوازیں مفت میں پیدا کریں اور ڈاؤن لوڈ کریں، جیسے جیسسی کی آواز، C3PO کی آواز، گھوسٹ فیس کی آواز...',
    keywords: 'TikTok آواز جنریٹر, AI TikTok آواز, Tik Tok آواز جنریٹر',
    main_title_p01: 'TikTok آواز جنریٹر',
    main_title_p02: 'مزاحیہ TikTok آوازیں پیدا کریں: جیسسی کی آواز، C3PO کی آواز، گھوسٹ فیس کی آواز',
    main_textarea_holder: 'یہاں متن ٹائپ یا چسپاں کریں',
    main_genvoice: 'پیدا کریں',
    main_generating: 'پیدا کیا جا رہا ہے',
    main_input_empty: 'براہ کرم متن ٹائپ یا چسپاں کریں',
    daily_usage_limit_msg: '1) آپ نے آج کے لیے زیادہ سے زیادہ استعمال کی حد کو پہنچا دیا ہے۔ براہ کرم کل دوبارہ آئیں۔ 2) اگر آپ کو فوری طور پر مزید استعمال کی ضرورت ہے تو براہ کرم ہمیں ای میل کریں۔',
    text_max_prompt1: 'براہ کرم الفاظ کی تعداد کو محدود کریں',
    text_max_prompt2: 'الفاظ یا کم!',
    popup_nologin_title: 'اپنے سبسکرپشن پلان کو اپ گریڈ کریں',
    popup_nologin_desc: 'پریمیم سبسکرپشن میں اپ گریڈ کر کے مزید آواز جنریشن وقت کو ان لاک کریں۔',
    popup_nologin_btntext: 'سبسکرپشن پلانز دیکھیں',
    popup_nosub_title: 'اپنے سبسکرپشن پلان کو اپ گریڈ کریں',
    popup_nosub_desc: 'پریمیم سبسکرپشن میں اپ گریڈ کر کے مزید آواز جنریشن وقت کو ان لاک کریں۔',
    popup_nosub_btntext: 'سبسکرپشن پلانز دیکھیں',
    popup_sublimit_title: 'سبسکرپشن ختم ہو گئی ہے، براہ کرم اپ گریڈ کریں',
    popup_sublimit_desc: 'ہماری پریمیم خصوصیات اور لامحدود رسائی کے ساتھ اپنے تجربے کو بہتر بنائیں۔',
    popup_sublimit_btntext: 'مزید خریدنے کے لیے جائیں',
  },
  pricing: {
    new_features_alert: "📣 ہم نے ادا شدہ ممبران کے لیے 🎙️ آواز کی نقل اور 🤖 API خدمات شروع کر دی ہیں! (ادائیگی کے بعد، ان خصوصیات کے لیے مدد کے لیے tiktokaivoicetool@gmail.com پر ای میل کریں)۔ اس کے علاوہ، کسی بھی حسب ضرورت درخواست کے لیے ہمیں ای میل کرنے میں کوئی ہچکچاہٹ نہ کریں😃", // 新增
    pricing_title: 'انفرادی تخلیق کاروں سے لے کر سب سے بڑے اداروں تک، ہمارے پاس آپ کے لیے ایک منصوبہ ہے۔',
    pricing_desc: '100+ قدرتی، انسانی آوازیں۔ 15+ زبانیں۔ اور تقریباً ہر کسی کے لیے سب سے کم قیمتیں',
    sub_free_name: 'مفت',
    sub_free_desc: 'ان افراد کے لیے جو سب سے جدید AI آڈیو کو آزمانا چاہتے ہیں',
    sub_free_content: [
      'ماہانہ 1000 حروف کی حد',
      'روزانہ 5 جنریشنز',
    ],
    sub_starter_name: 'شروعاتی',
    sub_starter_desc: 'عالمی سامعین کے لیے پریمیم مواد بنانے والے مواد بنانے والوں کے لیے',
    sub_starter_content: [
      '1 آواز کی نقل',
      'ماہانہ 300,000 حروف کی حد (تقریباً 7 گھنٹے آڈیو)',
      'غیر محدود ڈاؤن لوڈز',
      '72 گھنٹوں میں ای میل سپورٹ',
      'نئی آوازوں اور خصوصیات تک ترجیحی رسائی',
    ],
    sub_pro_name: 'پرو',
    sub_pro_desc: 'ان مواد بنانے والوں کے لیے جو اپنے مواد کی پیداوار کو بڑھا رہے ہیں',
    sub_pro_content: [
      'API تک رسائی',
      '3 آواز کی نقلیں',
      'ماہانہ 1,000,000 حروف کی حد (تقریباً 24 گھنٹے آڈیو)',
      'غیر محدود ڈاؤن لوڈز',
      '48 گھنٹوں میں ای میل سپورٹ',
      'نئی آوازوں اور خصوصیات تک ترجیحی رسائی',
    ],
    period_year: 'سالانہ',
    period_month: 'ماہانہ',
    period_month_short: 'ماہ',
    billed_year: 'سالانہ بل کیا جاتا ہے',
    most_popular: 'سب سے مقبول',
    discount_quantity: '$48 بچائیں',
    buy_btntext: 'شروع کریں',
    pay_succ: 'ادائیگی کامیاب!',
    pay_succ_desc: 'آپ کی خریداری کے لیے شکریہ۔ آپ کا لین دین کامیابی سے پروسیس کر دیا گیا ہے۔',
    pay_succ_btntext: 'TikTok Voice استعمال کرنا شروع کریں',
    pay_fail: 'ادائیگی ناکام!',
    pay_fail_desc: "معذرت، ہم آپ کے لین دین کو پروسیس نہیں کر سکے۔ براہ کرم دوبارہ کوشش کریں یا ہماری سپورٹ ٹیم سے رابطہ کریں (tiktokaivoicetool@gmail.com)۔",
    pay_fail_btntext: 'مین پیج پر واپس جائیں',
  },
  setting: {
    setting: 'ترتیبات',
    setting_title: 'یہاں اپنا اکاؤنٹ، استعمال اور سبسکرپشن منظم کریں۔',
    basic_info: 'بنیادی معلومات', // 新增，放在 setting_title 下方
    user_name: 'نام',
    user_email: 'ای میل',
    user_account: 'اکاؤنٹ',
    user_subscript: 'موجودہ پلان',
    user_usage: 'استعمال',
    manage_subscript: 'سبسکرپشن کا انتظام کریں',
    use_limit_promote: "آپ نے اپنی 10000 حروف کی ماہانہ حد میں سے 5000 استعمال کر لیے ہیں۔",
    not_logged_in: 'آپ لاگ ان نہیں ہیں', // 新增
    go_to_login: 'لاگ ان پر جائیں >>', // 新增
  },
  login: { // 新增 login 对象，放在 setting 下方
    title: "TikTok Voice Generator",
    subtitle: "اپنے TikTok Voice Generator اکاؤنٹ تک رسائی حاصل کرنے کے لیے لاگ ان کریں",
    alreadyLoggedIn: "آپ پہلے سے ہی لاگ ان ہیں",
    goToHome: "ہوم پیج پر جائیں"
  },
  faq: {
    quest1: 'TikTok آواز جنریٹر - بہترین AI TikTok آواز کا ٹول',
    answer1_1: 'یہ ایک TikTok AI آواز جنریٹر ٹول ہے جو جدید ترین tts TikTok ٹیکنالوجی سے تیار کیا گیا ہے، یہ TikTok آوازیں پیدا کر سکتا ہے جیسے جیسسی کی آواز (عورت کی آواز)، سِری کی آواز، گھوسٹ فیس کی آواز، C3PO کی آواز (روبوٹ کی آواز)، گہری آواز (کہانی سنانے والا)، اسکائی کی آواز، گرم آواز، بیسٹی کی آواز، ہیرو کی آواز (چیریس کی آواز)، ہمدرد آواز، سنجیدہ آواز، جوی کی آواز، اسٹچ کی آواز، اسٹورم ٹروپر کی آواز (اسٹار وارز)، راکٹ کی آواز (گارڈینز آف دی گلیکسی)۔',
    answer1_2: 'AI TikTok آوازیں جو جلد ہی تعاون یافتہ ہوں گی ان میں شامل ہیں: ڈیرک کی آواز، ٹرکسٹر کی آواز، آسٹن بٹلر کی آواز، خبریں رپورٹ کرنے والا کی آواز، ایڈم کی آواز، کتے کی آواز، مائلی سائرس کی آواز، الیکسا کی آواز، خلائی مخلوق کی آواز، جانور کی آواز، بچے کی آواز، کمپیوٹر کی آواز، چپمنک کی آواز، ایکو کی آواز، npr کی آواز۔',
    answer1_3: 'اس کے علاوہ، TikTok آواز جنریٹر ٹول متعدد دیگر زبانوں کو بھی سپورٹ کرتا ہے، جن میں چینی، جاپانی، کورین، ویتنامی، تھائی، ہندی، فارسی، روسی، جرمن، فرانسیسی، رومانی، چیک، اسپینش، پرتگالی، بنگالی، اطالوی، عربی، اردو، روایتی چینی اور مالائی زبانیں شامل ہیں۔',
    answer1_4: 'اگر آپ کو فوراً کسی مخصوص آواز کی ضرورت ہے، تو براہ کرم مجھے ای میل کریں۔',

quest2: 'TikTok آواز جنریٹر کے فوائد کیا ہیں؟',
    answer2_1: '- TikTok آواز جنریٹر ٹول مختلف قسم کی آوازیں پیدا کر سکتا ہے، جو اکثر TikTok ویڈیوز میں استعمال ہوتی ہیں۔',
    answer2_2: '- یہ جدید ترین AI آواز جنریٹر ہے جو انسانی مشابہ tiktok tts آوازیں پیدا کر سکتا ہے۔',
    answer2_3: '- یہ پی سی پر ویڈیوز ایڈٹ کرنے کے لئے زیادہ آسان ہے۔',
    answer2_4: '- آپ کچھ آوازیں استعمال کر سکتے ہیں جو فی الحال TikTok APP tts میں نہیں ملتی ہیں۔',

quest3: 'TikTok آواز جنریٹر کیسے استعمال کریں؟',
    answer3_1: 'TikTok آواز جنریٹر کا استعمال بہت آسان ہے:',
    answer3_2: '- زبان اور لہجے کی آواز منتخب کریں۔',
    answer3_3: '- متن کو آواز میں تبدیل کرنے کے لئے انپٹ باکس میں ٹائپ کریں۔',
    answer3_4: '- پیدا کریں بٹن دبائیں اور چند سیکنڈ انتظار کریں۔',
    answer3_5: '- TikTok AI آواز پلے کریں یا ڈاؤن لوڈ کریں۔',

quest4: 'مشہور اور مزاحیہ TikTok آوازیں کونسی ہیں؟',
    answer4_1: 'سب سے مشہور مردانہ TikTok آواز گہری آواز ہے، جو باقاعدہ طور پر کہانی سنانے والا کے طور پر جانی جاتی ہے۔',
    answer4_2: 'مزاحیہ TikTok آواز کے لئے، میں ذاتی طور پر گھوسٹ فیس کی آواز اور C3PO کی آواز کی سفارش کرتا ہوں۔ یہ آوازیں اکثر مزاحیہ TikTok ویڈیوز کو ڈب کرنے کے لئے استعمال ہوتی ہیں کیونکہ یہ آسانی سے پہچانی جا سکتی ہیں اور تیزی سے ناظرین کو اپنی طرف متوجہ کر سکتی ہیں۔',

quest5: 'سب سے پریشان کن TikTok آوازیں کونسی ہیں؟',
    answer5_1: 'میرا ماننا ہے کہ حقیقت میں کوئی "پریشان کن" TikTok آواز نہیں ہے۔',
    answer5_2: 'یہ زیادہ تر اس بات پر منحصر ہے کہ آواز کس مواد اور سیاق و سباق میں استعمال ہو رہی ہے، جو کچھ لوگوں کو غیر آرام دہ بنا سکتی ہے۔ مثال کے طور پر، کچھ لوگ جیسسی کی آواز کو پریشان کن سمجھ سکتے ہیں کیونکہ یہ TikTok پر کسی خاص مدت کے دوران بہت زیادہ ظاہر ہوئی ہے۔ تاہم، اس کی آواز اور معیار کی بنا پر، یہ حقیقت میں ایک زبردست TikTok آواز اثر ہے۔ اس کے علاوہ، TikTok آواز کی اداکارہ جو اس کے پیچھے ہے وہ بہت پیشہ ورانہ ہے اور خود بھی اپنے ویڈیوز TikTok پر پوسٹ کرتی ہے۔',
    answer5_3: 'لہذا، میں سب کو مشورہ دیتا ہوں کہ وہ اپنی تشویشات اور تعصبات کو ایک طرف رکھیں، اس ٹول کی آوازوں کو کئی بار سنیں اور صبر و تحمل کے ساتھ اپنے لئے بہترین TikTok آواز اثر تلاش کریں۔',

quest6: 'TikTok آواز جنریٹر کا استعمال کر کے جیسسی کی آواز کیسے پیدا کریں؟',
    answer6_1: '- TikTok آواز جنریٹر ٹول کے پہلے ڈراپ ڈاؤن مینو میں، English (US) منتخب کریں، پھر دوسرے ڈراپ ڈاؤن مینو میں، جیسسی کی آواز (عورت کی آواز) منتخب کریں۔',
    answer6_2: '- انپٹ باکس میں متن ٹائپ کریں اور پیدا کریں بٹن پر کلک کریں۔',
    answer6_3: '- چند سیکنڈ یا دس سیکنڈ تک انتظار کریں، آپ AI آواز سنیں گے۔ آپریشن بار میں، نیچے کی طرف تیر کے ساتھ بٹن تلاش کریں اور ڈاؤن لوڈ کرنے کے لئے کلک کریں۔',

quest7: 'TikTok آواز جنریٹر کا استعمال کر کے C3PO کی آواز کیسے پیدا کریں؟',
    answer7_1: '- TikTok آواز جنریٹر ٹول کے پہلے ڈراپ ڈاؤن مینو میں، English (US) منتخب کریں، پھر دوسرے ڈراپ ڈاؤن مینو میں، C3PO کی آواز (روبوٹ کی آواز) منتخب کریں۔',
    answer7_2: '- انپٹ باکس میں متن ٹائپ کریں اور پیدا کریں بٹن پر کلک کریں۔',
    answer7_3: '- چند سیکنڈ یا دس سیکنڈ تک انتظار کریں، آپ AI آواز سنیں گے۔ آپریشن بار میں، نیچے کی طرف تیر کے ساتھ بٹن تلاش کریں اور ڈاؤن لوڈ کرنے کے لئے کلک کریں۔',

quest8: 'TikTok آواز جنریٹر کا استعمال کر کے گھوسٹ فیس کی آواز کیسے پیدا کریں؟',
    answer8_1: '- TikTok آواز جنریٹر ٹول کے پہلے ڈراپ ڈاؤن مینو میں، English (US) منتخب کریں، پھر دوسرے ڈراپ ڈاؤن مینو میں، گھوسٹ فیس کی آواز منتخب کریں۔',
    answer8_2: '- انپٹ باکس میں متن ٹائپ کریں اور پیدا کریں بٹن پر کلک کریں۔',
    answer8_3: '- چند سیکنڈ یا دس سیکنڈ تک انتظار کریں، آپ AI آواز سنیں گے۔ آپریشن بار میں، نیچے کی طرف تیر کے ساتھ بٹن تلاش کریں اور ڈاؤن لوڈ کرنے کے لئے کلک کریں۔',

quest9: 'TikTok آواز جنریٹر کا استعمال کر کے سِری کی آواز کیسے پیدا کریں؟',
    answer9_1: '- TikTok آواز جنریٹر ٹول کے پہلے ڈراپ ڈاؤن مینو میں، English (US) منتخب کریں، پھر دوسرے ڈراپ ڈاؤن مینو میں، سِری کی آواز منتخب کریں۔',
    answer9_2: '- انپٹ باکس میں متن ٹائپ کریں اور پیدا کریں بٹن پر کلک کریں۔',
    answer9_3: '- چند سیکنڈ یا دس سیکنڈ تک انتظار کریں، آپ AI آواز سنیں گے۔ آپریشن بار میں، نیچے کی طرف تیر کے ساتھ بٹن تلاش کریں اور ڈاؤن لوڈ کرنے کے لئے کلک کریں۔',

quest10: 'TikTok ویڈیو میں TikTok آواز اثرات کیسے شامل کریں؟',
    answer10_1: 'اگر آپ جاننا چاہتے ہیں کہ TikTok پر سرکاری TikTok آوازیں کیسے استعمال کریں، میں جلد ہی اس موضوع پر ایک خصوصی بلاگ پوسٹ لکھوں گا۔',
    answer10_2: 'یہاں، میں وضاحت کروں گا کہ TikTok آواز جنریٹر سے آواز پیدا کرنے اور ڈاؤن لوڈ کرنے کے بعد TikTok پر اپنی آواز کیسے پوسٹ کریں۔',
    answer10_3: '1. اگر آپ نے اپنے پی سی پر TikTok آواز پیدا کی ہے اور اسے TikTok یا اپنے فون پر کسی دوسرے ویڈیو ایڈیٹنگ ایپ پر اپلوڈ کرنا چاہتے ہیں، تو آپ کو آواز فائل کو اپنے فون میں منتقل کرنا ہو گا۔ آئی فونز کے لئے، آپ ایئرڈراپ کا استعمال کر کے منتقل کر سکتے ہیں۔ اینڈروئیڈ فونز کے لئے، میں اس عمل سے زیادہ واقف نہیں ہوں، لیکن آپ کو یقینی طور پر یہ کرنے کے لئے طریقے اور اوزار ملیں گے۔',
    answer10_4: '2. جب آواز فائل آپ کے فون میں منتقل ہو جائے، تو آپ TikTok کھول سکتے ہیں:',
    answer10_5: '- انٹرفیس کے نیچے "+" بٹن پر کلک کریں، اپنے فون میں ویڈیو منتخب کریں۔',
    answer10_6: '- ویڈیو اپلوڈ کرنے کے بعد، اسکرین کے دائیں جانب ایک مربع آئیکن تلاش کریں اور ایڈیٹنگ صفحے میں داخل ہوں۔',
    answer10_7: '- صفحے کے نیچے "آواز شامل کریں" بٹن تلاش کریں اور تازہ منتقل کی گئی آواز فائل منتخب کرنے کے لئے کلک کریں۔',
    answer10_8: '- اس انٹرفیس میں، آپ ویڈیو میں کچھ ایڈجسٹمنٹس کر سکتے ہیں، پھر پوسٹ کرنے کے لئے اوپر دائیں کونے میں بٹن پر کلک کریں۔',

quest11: 'TikTok Voice مفت ہے؟',
    answer11: 'جی ہاں، یہ ایک مفت TikTok AI آواز متن سے آواز جنریٹر ٹول ہے۔',

quest12: 'میں کہاں اپنی TikTok ویڈیو میں مشہور TikTok آواز پیدا کر سکتا ہوں؟',
    answer12: 'آپ https://tiktokvoice.net/ پر جا سکتے ہیں تاکہ آپ کی ویڈیو میں AI TikTok آواز شامل کریں۔'
  },
  // host: 'http://tiktokvoice.net'
  host: 'https://tiktokvoice.net'
}